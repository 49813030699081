
const prefix = "myk";
const iconName = "settings";
const width = 512;
const height = 512;
const ligatures = [];
const unicode = "";
const svgPathData = "M248,248h16v16h-16V248z M429.8,279.4c2.2-15.5,2.2-31.2,0-46.7l49.4-39.3c4.5-3.8,5.7-10.2,2.8-15.3 l-46.8-82.5c-2.1-3.7-6-6-10.3-6c-1.4,0-2.7,0.2-4,0.7l-58.3,23.8c-12.1-9.6-25.5-17.4-39.7-23.4L314,27.5c-0.7-5.7-5.5-9.9-11.2-10 h-93.6c-5.8,0-10.7,4.3-11.5,10l-8.9,63.2c-14.2,6-27.6,13.9-39.7,23.4L90.7,90.3c-1.4-0.5-2.8-0.7-4.2-0.7c-4.2,0-8,2.3-10.1,6 L29.8,178c-3,5.1-1.8,11.6,2.8,15.3L82,232.6c-1,7.8-1.6,15.6-1.6,23.4c0.1,7.8,0.6,15.6,1.6,23.4l-49.4,39.3 c-4.5,3.8-5.7,10.2-2.8,15.3l46.8,82.5c2.1,3.7,6,6,10.3,6c1.4,0,2.7-0.2,4-0.7l58.3-23.8c12.1,9.6,25.5,17.4,39.7,23.4l8.9,63.2 c0.7,5.7,5.6,10,11.3,10h93.6c5.8,0,10.7-4.3,11.5-10l8.9-63.2c14.2-6,27.6-13.9,39.7-23.4l58.3,23.8c1.4,0.5,2.8,0.7,4.2,0.7 c4.2,0,8-2.3,10.1-6l46.8-82.5c2.8-5.1,1.7-11.5-2.8-15.3L429.8,279.4z M383.5,238.6c0.8,5.8,1.2,11.6,1.2,17.4 c0,5-0.5,10.3-1.2,17.4l-3.3,26.9l20.8,16.7l25.3,19.9l-16.4,28.9l-29.7-12.2l-24.3-10l-21.1,16.2c-9,7-18.8,12.8-29.3,17.4 l-24.8,10.3l-3.7,26.9l-4.7,32.2h-32.8l-4.5-32.2l-3.7-26.9l-24.8-10.3c-10.3-4.4-19.9-10.1-28.8-16.9l-21.3-16.7l-24.8,10.3 L101.9,366l-16.4-28.9l25.3-19.9l20.8-16.7l-3.3-26.9c-0.7-7.4-1.2-12.9-1.2-17.6c0-4.8,0.5-10.3,1.2-17.4l3.3-26.9l-20.8-16.7 l-25.3-19.9l16.4-28.9l29.7,12.2l24.3,10l21.1-16.2c9-7,18.8-12.8,29.3-17.4l24.8-10.3l3.7-26.9l4.7-32.2H272l4.5,32.2l3.7,26.9 l24.8,10.3c10.3,4.4,19.9,10.1,28.8,16.9l21.3,16.7l24.8-10.3l29.7-12.2l16.4,28.9l-25,20.3l-20.8,16.7l3.3,26.9L383.5,238.6z M256,160.6c-52.7-1-96.2,40.9-97.2,93.5s40.9,96.2,93.5,97.2c52.7,1,96.2-40.9,97.2-93.5c0-0.6,0-1.2,0-1.8 C350,203.8,308.1,161.1,256,160.6C256,160.6,256,160.6,256,160.6z M256,303.7c-26.3,0.5-48.1-20.4-48.6-46.8 c-0.5-26.3,20.4-48.1,46.8-48.6c26.3-0.5,48.1,20.4,48.6,46.8c0,0.3,0,0.6,0,0.9C302.9,282.1,282,303.4,256,303.7z";

export default {
  prefix,
  iconName,
  icon: [
    width,
    height,
    ligatures,
    unicode,
    svgPathData
  ]
};
