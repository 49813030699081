// @ts-nocheck

import _ from "lodash";
import PropTypes from "prop-types";
import React, { Component, PureComponent, Fragment } from "react";

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "../../../config/fontAwesomeConfig";


type ComponentPropsType = {
  name: IconNameTypes;
  icon: Object;
  color: string;
  size: number;
  style: Object;
};

type IconNameTypes =
  // ─── SOLID ───────────────
  "eye"|
  "eye-slash"|
  "times" |
  "chevron-right" |
  "chevron-left" |
  "chevron-up" |
  "chevron-down" |
  "check" |
  "sync" |
  "info" |
  "exclamation" |
  "exclamation-circle" |
  "exclamation-triangle" |
  "envelope" |
  "phone-alt" |
 " phone-volume" |
  "coins" |
  "credit-card" |
  "exchange-alt" |
  "money-bill-wave" |
  "desktop" |
  "download" |
  "print" |
  "question-circle" |
  "bullhorn" |
  "caret-up" |
  "caret-down" |
  // ─── REGULAR ─────────────
  "far user" |
  "far comment" |
  "far file-alt" |
  "far comment-dots" |
  "far copy" |
  // ─── BRANDS ──────────────
  "fab facebook" |
  "fab twitter" |
  "fab linkedin" |
  "fab instagram"
  ;

const DEFAULT_PREFIX = "fas";


class Icon extends Component<ComponentPropsType> {

  static addIcon = iconObject => {
    library.add(iconObject);
  };

  shouldComponentUpdate(nextProps) {
    return !_.isEqual(nextProps, this.props);
  }

  render() {
    const {
      name,
      icon,
      color,
      size,
      style,
      ...otherProps
    } = this.props;
    /* eslint-disable-next-line */

    const stylesMerged = Object.assign(
      {
        objectFit: "contain",
        objectPosition: "center center"
      },
      style,
      size && {
        width: `${Number(size)}px`,
        height: `${Number(size)}px`
      },
      color && {
        color: `${color}`
      }
    );

    const iconProp = (() => {
      if (icon && _.isPlainObject(icon)) {
        return icon;
      }
      const iconNameArray = _.split(this.props.name, " ");
      return iconNameArray.length === 1 ? _.concat(DEFAULT_PREFIX, iconNameArray) : iconNameArray;
    })();

    return (
      <FontAwesomeIcon
        css={stylesMerged}
        icon={iconProp}
        {...otherProps} />
    );
  }
}



Icon.propTypes = {
  name: PropTypes.string,
  icon: PropTypes.object,
  size: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ]),
  color: PropTypes.string,
  style: PropTypes.object
};

export default Icon;
