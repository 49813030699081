
const prefix = "myk";
const iconName = "calendar";
const width = 512;
const height = 512;
const ligatures = [];
const unicode = "";
const svgPathData = "M405.3,85.3H384V42.7h-42.7v42.7H170.7V42.7H128v42.7h-21.3c-23.5,0-42.5,19-42.5,42.5c0,0.1,0,0.1,0,0.2 L64,426.7c0,23.6,19.1,42.7,42.7,42.7h298.7c23.5-0.1,42.6-19.1,42.7-42.7V128C447.9,104.5,428.9,85.4,405.3,85.3z M405.3,426.7 H106.7V192h298.7V426.7z M149.3,234.7H256v106.7H149.3V234.7z";

export default {
  prefix,
  iconName,
  icon: [
    width,
    height,
    ligatures,
    unicode,
    svgPathData
  ]
};
