const prefix = "myk";
const iconName = "double-check";
const width = 512;
const height = 512;
const ligatures = [];
const unicode = "";
const svgPathData = "M379.6,153l-29-29L220,254.5l29,29L379.6,153z M466.9,124L249,341.9L162.9,256l-29,29L249,400.2L496.2,153 L466.9,124z M17.3,285l115.1,115.1l29-29L46.5,256L17.3,285z";

export default {
  prefix,
  iconName,
  icon: [
    width,
    height,
    ligatures,
    unicode,
    svgPathData
  ]
};
