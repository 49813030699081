
const prefix = "myk";
const iconName = "plus-circle";
const width = 512;
const height = 512;
const ligatures = [];
const unicode = "";
const svgPathData = "M278.7,142.6h-45.4v90.7h-90.7v45.4h90.7v90.7h45.4v-90.7h90.7v-45.4h-90.7V142.6z M256,29.2 C130.7,29.2,29.2,130.7,29.2,256S130.7,482.8,256,482.8S482.8,381.3,482.8,256C482.8,130.8,381.2,29.2,256,29.2z M256,437.4 c-100.2,0-181.4-81.2-181.4-181.4c0-100.2,81.2-181.4,181.4-181.4c100.2,0,181.4,81.2,181.4,181.4v0 C437.3,356.2,356.2,437.3,256,437.4z";

export default {
  prefix,
  iconName,
  icon: [
    width,
    height,
    ligatures,
    unicode,
    svgPathData
  ]
};
