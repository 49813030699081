const prefix = "myk";
const iconName = "camera";
const width = 512;
const height = 512;
const ligatures = [];
const unicode = "";
const svgPathData = "M256,202.3c43.1,0,78,34.9,78,78s-34.9,78-78,78s-78-34.9-78-78S212.9,202.3,256,202.3z M182.8,36.5l-44.6,48.8 H60.9c-26.9,0.1-48.7,21.9-48.8,48.8v292.6c0.1,26.9,21.9,48.7,48.8,48.8h390.2c26.9-0.1,48.7-21.9,48.8-48.8V134.1 c-0.1-26.9-21.9-48.7-48.8-48.8h-77.3l-44.6-48.8H182.8z M256,402.3c-67.3,0-121.9-54.6-121.9-121.9S188.7,158.5,256,158.5 S377.9,213,377.9,280.4S323.3,402.3,256,402.3z";

export default {
  prefix,
  iconName,
  icon: [
    width,
    height,
    ligatures,
    unicode,
    svgPathData
  ]
};
