
const prefix = "myk";
const iconName = "info";
const width = 512;
const height = 512;
const ligatures = [];
const unicode = "";
const svgPathData = "M230.4,128h51.2v51.2h-51.2V128z M230.4,230.4h51.2V384h-51.2V230.4z M256,0 C114.6,0,0,114.6,0,256s114.6,256,256,256s256-114.6,256-256l0,0C512,114.6,397.4,0,256,0z M256,460.8 c-113.1,0-204.8-91.7-204.8-204.8S142.9,51.2,256,51.2S460.8,142.9,460.8,256l0,0C460.6,369,369,460.6,256,460.8z";

export default {
  prefix,
  iconName,
  icon: [
    width,
    height,
    ligatures,
    unicode,
    svgPathData
  ]
};
