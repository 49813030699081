const prefix = "myk";
const iconName = "logout";
const width = 512;
const height = 512;
const ligatures = [];
const unicode = "";
const svgPathData = "M229.1,126l-35.8,35.8l66.5,66.5H-1.3v51.2h261.1l-66.5,66.5 l35.8,35.8l128-128L229.1,126z M459.5,433.2H254.7v51.2h204.8c28.2-0.1,51.1-23,51.2-51.2V74.8c-0.1-28.2-23-51.1-51.2-51.2H254.7 v51.2h204.8V433.2z";

export default {
  prefix,
  iconName,
  icon: [
    width,
    height,
    ligatures,
    unicode,
    svgPathData
  ]
};
