
const prefix = "myk";
const iconName = "error";
const width = 512;
const height = 512;
const ligatures = [];
const unicode = "";
const svgPathData = "M256,0C114.6,0,0,114.6,0,256c0,141.4,114.6,256,256,256c141.4,0,256-114.6,256-256 C512.1,114.7,397.7,0.1,256.5,0C256.3,0,256.1,0,256,0z M256,460.8c-113.1,0-204.8-91.7-204.8-204.8S142.9,51.2,256,51.2 S460.8,142.9,460.8,256C460.6,369.1,369,460.7,256,460.8z M347.9,128L256,219.9L164.1,128L128,164.1l91.9,91.9L128,347.9l36.1,36.1 l91.9-91.9l91.9,91.9l36.1-36.1L292.1,256l91.9-91.9L347.9,128z";

export default {
  prefix,
  iconName,
  icon: [
    width,
    height,
    ligatures,
    unicode,
    svgPathData
  ]
};
