const prefix = "myk";
const iconName = "delete";
const width = 512;
const height = 512;
const ligatures = [];
const unicode = "";
const svgPathData = "M359.1,178.7v257.8H152.9V178.7H359.1 M320.4,24H191.6l-25.8,25.8H75.5v51.6h360.9V49.8h-90.2L320.4,24z M410.7,127.1H101.3v309.3c0.1,28.4,23.1,51.5,51.6,51.6h206.2c28.4-0.1,51.5-23.1,51.6-51.6V127.1z";

export default {
  prefix,
  iconName,
  icon: [
    width,
    height,
    ligatures,
    unicode,
    svgPathData
  ]
};
