
const prefix = "myk";
const iconName = "shield";
const width = 512;
const height = 512;
const ligatures = [];
const unicode = "";
const svgPathData = "M256,21.3L64,106.7v128.9c0,118.2,78.3,222.3,192,255.1c113.7-32.8,192-136.9,192-255.1V106.7L256,21.3z M277.4,362.7h-42.7v-128h42.7V362.7z M277.4,192h-42.7v-42.7h42.7V192z";

export default {
  prefix,
  iconName,
  icon: [
    width,
    height,
    ligatures,
    unicode,
    svgPathData
  ]
};
