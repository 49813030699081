/* eslint-disable import-helpers/order-imports */
/* eslint-disable import/max-dependencies */
// eslint-disable-next-line import/no-unresolved
import { library, config } from "@fortawesome/fontawesome-svg-core";

// import { fas } from "@fortawesome/free-solid-svg-icons";
// import { far } from "@fortawesome/free-regular-svg-icons";
// import { fab } from "@fortawesome/free-brands-svg-icons";
// library.add(fas, far, fab);



// ─── SOLID ───────────
import {
  faTimes,
  faChevronRight,
  faChevronLeft,
  faChevronUp,
  faChevronDown,
  faCheck,
  faSync,
  faInfo,
  faInfoCircle,
  faExclamation,
  faExclamationCircle,
  faExclamationTriangle,
  faEnvelope,
  faPhoneAlt,
  faPhoneVolume,
  faCoins,
  faCreditCard,
  faExchangeAlt,
  faMoneyBillWave,
  faDesktop,
  faDownload,
  faPrint,
  faQuestionCircle,
  faMapMarkerAlt,
  faSignOutAlt,
  faHistory,
  faBullhorn,
  faAsterisk,
  faEye,
  faEyeSlash,
  faArrowLeft,
  faCaretDown,
  faCaretUp,
  faUniversity
} from "@fortawesome/free-solid-svg-icons";


// ─── REGULAR ─────────
import {
  faUser,
  faComment,
  faFileAlt,
  faCommentDots,
  faCopy,
  faMoneyBillAlt,
  faCalendarAlt
} from "@fortawesome/free-regular-svg-icons";


// ─── BRANDS ──────────
import {
  faFacebook,
  faTwitter,
  faLinkedin,
  faInstagram
} from "@fortawesome/free-brands-svg-icons";


// ─── CUSTOM ──────────
import emailIcon from "../assets/font-icon-svg/emailIcon";
import userIcon from "../assets/font-icon-svg/userIcon";
import supportAgentIcon from "../assets/font-icon-svg/supportAgentIcon";
import logoutIcon from "../assets/font-icon-svg/logoutIcon";
import checkIcon from "../assets/font-icon-svg/checkIcon";
import errorIcon from "../assets/font-icon-svg/errorIcon";
import warningIcon from "../assets/font-icon-svg/warningIcon";
import successIcon from "../assets/font-icon-svg/successIcon";
import shieldIcon from "../assets/font-icon-svg/shieldIcon";
import infoIcon from "../assets/font-icon-svg/infoIcon";
import checkCircleIcon from "../assets/font-icon-svg/checkCircleIcon";
import plusCircleIcon from "../assets/font-icon-svg/plusCircleIcon";
import calendarIcon from "../assets/font-icon-svg/calendarIcon";
import cameraIcon from "../assets/font-icon-svg/cameraIcon";
import deleteIcon from "../assets/font-icon-svg/deleteIcon";
import doubleCheck from "../assets/font-icon-svg/doubleCheck";
import settingsIcon from "../assets/font-icon-svg/settingsIcon";


config.autoAddCss = true;
config.autoReplaceSvg = false;
config.observeMutations = false;
config.familyPrefix = "icon";


library.add(
  // ─── SOLID ───────────────
  faTimes,
  faChevronRight,
  faChevronLeft,
  faChevronUp,
  faChevronDown,
  faCheck,
  faSync,
  faInfo,
  faInfoCircle,
  faExclamation,
  faExclamationCircle,
  faExclamationTriangle,
  faEnvelope,
  faPhoneAlt,
  faPhoneVolume,
  faCoins,
  faCreditCard,
  faExchangeAlt,
  faMoneyBillWave,
  faDesktop,
  faDownload,
  faPrint,
  faQuestionCircle,
  faMapMarkerAlt,
  faSignOutAlt,
  faHistory,
  faBullhorn,
  faAsterisk,
  faEye,
  faEyeSlash,
  faArrowLeft,
  faCaretDown,
  faCaretUp,
  faUniversity,
  // ─── REGULAR ─────────────
  faUser,
  faComment,
  faFileAlt,
  faCommentDots,
  faCopy,
  faMoneyBillAlt,
  faCalendarAlt,
  // ─── BRANDS ──────────────
  faFacebook,
  faTwitter,
  faLinkedin,
  faInstagram,
  // ─── CUSTOM ──────────────
  emailIcon,
  userIcon,
  supportAgentIcon,
  logoutIcon,
  checkIcon,
  errorIcon,
  warningIcon,
  successIcon,
  shieldIcon,
  infoIcon,
  checkCircleIcon,
  calendarIcon,
  cameraIcon,
  plusCircleIcon,
  deleteIcon,
  doubleCheck,
  settingsIcon
);



export default {};
