
const prefix = "myk";
const iconName = "user";
const width = 512;
const height = 512;
const ligatures = [];
const unicode = "";
const svgPathData = "M256,60.8c37.1,0,67.2,30.1,67.2,67.2s-30.1,67.2-67.2,67.2s-67.2-30.1-67.2-67.2l0,0 C188.8,90.9,218.9,60.8,256,60.8 M256,348.8c95,0,195.2,46.6,195.2,67.2v35.2H60.8V416C60.8,395.5,161,348.8,256,348.8 M256,0 c-70.7,0-128,57.3-128,128s57.3,128,128,128s128-57.3,128-128l0,0C384,57.3,326.8,0,256.1,0C256.1,0,256,0,256,0z M256,288 C170.5,288,0,330.9,0,416v96h512v-96C512,330.9,341.4,288,256,288z";

export default {
  prefix,
  iconName,
  icon: [
    width,
    height,
    ligatures,
    unicode,
    svgPathData
  ]
};
