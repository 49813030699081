
const prefix = "myk";
const iconName = "support-agent";
const width = 512;
const height = 512;
const ligatures = [];
const unicode = "";
const svgPathData = "M484.4,255.4C484,128.2,380.5,25.4,253.2,25.8C126,26.2,23.2,129.7,23.6,257 c-15.9,9-25.7,25.8-25.6,44v51.2c0.1,28.2,23,51.1,51.2,51.2h25.6V247.2C74.8,148.3,155,68,254,68s179.2,80.2,179.2,179.2V429H228.4 v51.2h204.8c28.2-0.1,51.1-23,51.2-51.2v-31.2c15.7-8.1,25.6-24.3,25.6-42v-58.9C509.8,279.4,500,263.4,484.4,255.4z M177.2,249.8 c14.1,0,25.6,11.5,25.6,25.6S191.3,301,177.2,301s-25.6-11.5-25.6-25.6S163.1,249.8,177.2,249.8z M330.8,249.8 c14.1,0,25.6,11.5,25.6,25.6S344.9,301,330.8,301c-14.1,0-25.6-11.5-25.6-25.6S316.7,249.8,330.8,249.8z M407.6,225 c-14.1-84.2-93.9-141-178.1-126.9C151,111.3,95.4,181.9,100.9,261.3c64.1-26.2,110.8-82.8,124.4-150.8 C259.7,179.9,330.1,224.2,407.6,225z";

export default {
  prefix,
  iconName,
  icon: [
    width,
    height,
    ligatures,
    unicode,
    svgPathData
  ]
};
