
const prefix = "myk";
const iconName = "success";
const width = 512;
const height = 512;
const ligatures = [];
const unicode = "";
const svgPathData = "M256,0C114.6,0,0,114.6,0,256c0,141.4,114.6,256,256,256c141.4,0,256-114.6,256-256C511.9,114.6,397.4,0,256,0z M256,460.8c-113.1,0-204.8-91.7-204.8-204.8S142.9,51.2,256,51.2S460.8,142.9,460.8,256C460.6,369.1,369,460.7,256,460.8z M373.5,142.9L204.8,311.6l-66.3-66l-36.1,36.1L204.8,384l204.8-204.8L373.5,142.9z";

export default {
  prefix,
  iconName,
  icon: [
    width,
    height,
    ligatures,
    unicode,
    svgPathData
  ]
};
