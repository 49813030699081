
const prefix = "myk";
const iconName = "mail";
const width = 512;
const height = 512;
const ligatures = [];
const unicode = "";
const svgPathData = "M460.8,43.9H51.2C23,44.1,0.3,66.9,0.3,95.1L0,402.3c0.1,28.2,23,51.1,51.2,51.2h409.6 c28.2-0.1,51.1-23,51.2-51.2V95.1C511.9,66.9,489,44,460.8,43.9z M460.8,146.3L256,274.3l-204.8-128V95.1l204.8,128l204.8-128V146.3 z";

export default {
  prefix,
  iconName,
  icon: [
    width,
    height,
    ligatures,
    unicode,
    svgPathData
  ]
};
