
const prefix = "myk";
const iconName = "warning";
const width = 512;
const height = 512;
const ligatures = [];
const unicode = "";
const svgPathData = "M230.4,332.8h51.2V384h-51.2V332.8z M230.4,128h51.2v153.6h-51.2V128z M255.7,0 C114.4,0.1-0.1,114.9,0,256.3C0.1,397.6,114.9,512.1,256.3,512C397.5,511.9,512,397.3,512,256C512.1,114.7,397.6,0.1,256.3,0 C256.1,0,255.9,0,255.7,0z M256,460.8c-113.1,0-204.8-91.7-204.8-204.8c0-113.1,91.7-204.8,204.8-204.8 c113.1,0,204.8,91.7,204.8,204.8v0C460.8,369.1,369.1,460.8,256,460.8z";

export default {
  prefix,
  iconName,
  icon: [
    width,
    height,
    ligatures,
    unicode,
    svgPathData
  ]
};
