
const prefix = "myk";
const iconName = "check-circle";
const width = 512;
const height = 512;
const ligatures = [];
const unicode = "";
const svgPathData = "M213.3,16.6c-108.6,0-196.7,88.1-196.7,196.7S104.7,410,213.3,410S410,321.9,410,213.3S322,16.6,213.3,16.6z M213.3,370.7c-87,0-157.4-70.5-157.4-157.4S126.4,56,213.3,56s157.4,70.5,157.4,157.3C370.6,300.2,300.2,370.6,213.3,370.7z M303.9,126.3L174,256l-51.2-50.5L95.3,233l78.7,78.7l157.4-157.4L303.9,126.3z";

export default {
  prefix,
  iconName,
  icon: [
    width,
    height,
    ligatures,
    unicode,
    svgPathData
  ]
};
